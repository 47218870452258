//
//
//
//
//
//
import { isExternal as _isExternal } from '@/utils/validate';
export default {
  props: {
    to: {
      type: String,
      required: true
    }
  },
  computed: {
    isExternal: function isExternal() {
      return _isExternal(this.to);
    },
    type: function type() {
      if (this.isExternal) {
        return 'a';
      }

      return 'router-link';
    }
  },
  methods: {
    linkProps: function linkProps(to) {
      if (this.isExternal) {
        return {
          href: to,
          target: '_blank',
          rel: 'noopener'
        };
      }

      return {
        to: to
      };
    }
  }
};